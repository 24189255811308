<script>
const envs = [
  'dev',
  'integ',
  'staging',
  'demo',
  'prod',
]
const publicEnvs = [
  'demo',
  'prod',
]
const regexSuffix = RegExp('-(front|grafana)$')


class AppLink {
  constructor(env /* String */, href /* String */) {
    this.env = env
    this.href = href
  }

  static fromJson(env /* String */, host /* String */) {
    return new AppLink(env, `https://${host}`)
  }
}

class AppItem {
  constructor(name /* String */, links /* array[AppLink] */) {
    this.name = name
    this.links = links.sort((l1, l2) => envs.indexOf(l1.env) - envs.indexOf(l2.env))
  }

  static fromJson(key /* String */, envList /* array[] */) {
    return new AppItem(key, Object.entries(envList).map(envHost => AppLink.fromJson(...envHost)))
  }
}

export default {
  name: 'Hub',
  data () {
    return {
      searchText: null,
      showAll: false,
      apps: [], // array[AppItem]
    }
  },
  computed: {
    filtered () {
      return this.filter(this.apps, this.searchText || '', this.showAll)
    },
    itemIcon () {
      return this.showAll ? 'fa-brands fa-docker' : 'fas fa-diagram-project'
    },
  },
  async mounted () {
    this.apps = this.convertJson(await fetch('/apps.json').then(resp => resp.json()).then(data => data))
  },
  methods: {
    convertJson (data) {
      return Object.entries(data).map(item => AppItem.fromJson(...item))
    },
    capitalize (s) {
      return s && s[0].toUpperCase() + s.slice(1)
    },
    filter (apps, searchText, showAll) {
      return apps.reduce((lst, app) => {
        if (showAll) {
          lst.push(app)
        } else if (regexSuffix.test(app.name)) {
          const name = app.name.replace(regexSuffix, '')
          const links = app.links.filter(link => publicEnvs.includes(link.env))
          if (links.length) {
            lst.push({ name, links })
          }
        }
        return lst
      }, []).filter(
        app => app.name.toLowerCase().includes(searchText),
      )
    },
  },
}
</script>
<template>
  <section class="card hub">
    <div class="header">
      <div>
        <v-switch
          v-model="showAll"
          hide-details
          color="primary"
          class="switch"
        >
          <template #prepend="{id}">
            <v-label
              :for="id.value"
              clickable
            >
              {{ $gettext("Public projects") }}
            </v-label>
          </template>
          <template #append="{id}">
            <v-label
              :for="id.value"
              clickable
            >
              {{ $gettext("Show all") }}
            </v-label>
          </template>
        </v-switch>
      </div>
      <v-text-field
        v-model="searchText"
        hide-details
        single-line
        clearable
        prepend-icon="fas fa-search"
        variant="solo"
        density="compact"
        role="search"
        autofocus
        :placeholder="$gettext('Search')"
        class="search"
      />
    </div>
    <v-list class="scrollable">
      <v-list-item
        v-for="(item, i) in filtered"
        :key="i"
        :title="item.name"
        :prepend-icon="itemIcon"
      >
        <template #append>
          <v-btn
            v-for="link in item.links"
            :key="link.href"
            :href="link.href"
            target="_blank"
            class="text-none"
            variant="flat"
            prepend-icon="fas fa-laptop"
            rounded
          >
            {{ capitalize(link.env) }}
          </v-btn>
        </template>
      </v-list-item>
    </v-list>
  </section>
</template>
<style lang="scss" scoped>
.hub {
  flex-grow: 1;
  height: 100%;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.switch {
  display: inline-grid;
}
.search {
  max-width: 300px;
}
.scrollable {
  overflow-y: auto;
}

@media (width < 1200px) {
  .header {
    flex-direction: column;
    margin-bottom: 1ch;
  }
}
</style>
