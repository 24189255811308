<script>
import { fullscreenRoutes } from '@src/router'
import Toolbar from '@comp/layout/Toolbar.vue'
import NavigationDrawer from '@comp/layout/NavigationDrawer.vue'
import { useIndexStore } from '@src/store'

export default {
  name: 'App',
  components: {
    Toolbar,
    NavigationDrawer,
  },
  computed: {
    pageWithNavigation () {
      const store = useIndexStore()
      return !fullscreenRoutes.map(route => route.name).includes(store.route)
    },
    containerClass () {
      return this.pageWithNavigation ? 'with-navigation' : 'without-navigation'
    },
  },
}
</script>
<template>
  <v-app class="app">
    <NavigationDrawer v-if="pageWithNavigation" />
    <div
      class="container"
      :class="containerClass"
    >
      <Toolbar />
      <RouterView />
    </div>
  </v-app>
</template>
