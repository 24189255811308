import { fakeUser } from '@src/config'
import { useIndexStore } from '@src/store'
import { homeRoute, loginRoute, failRoute } from '@src/router'
import { getUser, loginUser, logoutUser } from './msal'

export function isAuthenticated () {
  try {
    const user = getUser()
    useIndexStore().changeUser(user)
    return user !== null
  } catch (e) {
    useIndexStore().changeUser(null)
    return false
  }
}

export async function login ($router) {
  if (fakeUser) {
    $router.push(homeRoute.path)
  } else {
    try {
      await loginUser()
    } catch (e) {
      $router.push(`${failRoute.path}?error=${e}`)
    }
  }
}

export async function logout ($router) {
  if (fakeUser) {
    $router.push(homeRoute.path)
  } else {
    try {
      await logoutUser()
      $router.push(loginRoute.path)
    } catch (e) {
      $router.push(`${failRoute.path}?error=${e}`)
    }
  }
}
