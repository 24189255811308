<script>
import WelcomePanel from '@comp/home/WelcomePanel.vue'
import Hub from '@comp/home/Hub.vue'
import { useIndexStore } from '@src/store'

export default {
  name: 'Home',
  components: {
    WelcomePanel,
    Hub,
  },
  async mounted () {
    const store = useIndexStore()
    store.changeRoute(this.$options.name)
  },
}
</script>
<template>
  <section class="view home">
    <WelcomePanel class="welcome" />
    <Hub class="hub" />
  </section>
</template>
<style lang="scss" scoped>
.home {
  max-height: calc(100vh - 50px);
}
.welcome {
  max-width: 40%;
}

@media (width < 1000px) {
  .home {
    flex-direction: column;
  }
  .welcome {
    max-width: 100%;
  }
}
</style>
