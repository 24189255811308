import { createRouter, createWebHistory } from 'vue-router'
import { isAuthenticated } from '@src/utils/auth'

// Pages
import Home from '@page/Home.vue'
import Login from '@page/Login.vue'
import Fail from '@page/Fail.vue'
import User from '@page/User.vue'

// only used to force to see translation to vue-gettext
const $gettext = s => s

// Auth pages start
export const homePath = '/'
export const loginPath = '/login'
export const requireAuth = (_to, _from, next) => {
  if (isAuthenticated()) {
    next()
  } else {
    next(loginPath)
  }
}
export const redirectToHomeIfConnected = (_to, _from, next) => {
  if (isAuthenticated()) {
    next(homePath)
  } else {
    next()
  }
}
export const loginRoute = {
  path: loginPath,
  name: 'Login',
  component: Login,
  beforeEnter: redirectToHomeIfConnected,
}
export const failRoute = {
  path: '/fail',
  name: 'Fail',
  component: Fail,
}
export const userRoute = {
  path: '/user',
  name: 'User',
  component: User,
  beforeEnter: requireAuth,
}
export const disconnectRoute = { /* no path, no component, just a link in the menu */
  name: 'Disconnect',
  icon: 'fas fa-sign-out-alt',
  title: $gettext('Logout'),
}
// Auth pages end
export const homeRoute = {
  path: homePath,
  name: 'Home',
  component: Home,
  icon: 'fas fa-house',
  title: $gettext('Home'),
  beforeEnter: requireAuth,
}

export const routes = [
  homeRoute,
  loginRoute,
  failRoute,
  userRoute,
]
export const fullscreenRoutes = [
  loginRoute,
  failRoute,
]
export const navigationRoutes = [
  homeRoute,
  disconnectRoute,
]
export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})
