import { defineStore } from 'pinia'

export const useIndexStore = defineStore('store', () => {
  const route = null
  const msal = {}
  const user = null
  function changeRoute (newRoute) {
    this.route = newRoute
  }
  function changeMsal (msal) {
    this.msal = msal || {}
  }
  function changeUser (newUser) {
    this.user = newUser
  }
  return {
    route,
    msal,
    user,
    changeRoute,
    changeMsal,
    changeUser,
  }
})
