<script>
export default {
  name: 'WelcomePanel',
}
</script>
<template>
  <section class="card welcome-panel">
    <div class="welcome-subtitle">
      {{ $gettext('Welcome to') }}
    </div>
    <div class="welcome-title">
      {{ $gettext('SDS application hub') }}
    </div>
    <p class="welcome-text">
      <!-- eslint-disable-next-line max-len -->
      {{ $gettext("Navigate through the list of SDS’s hosted projects, and access directly the application you are interested in.") }}
    </p>
    <v-icon class="welcome-icon">
      fas fa-project-diagram
    </v-icon>
  </section>
</template>
<style lang="scss" scoped>
.welcome-panel {
  overflow: auto;
  width: 100%;
  min-height: 10rem;
}
.welcome-title {
  margin-bottom: 1rem;
}
.welcome-text {
  font-size: 1.2rem;
  position: relative;
  z-index: 2;
  margin-bottom: 1rem;
}
.welcome-icon {
  position: absolute;
  font-size: 20rem !important;
  z-index: 1;
  opacity: 0.6;
  right: 2rem;
  bottom: 2rem;
}

@media (width <= 1200px), (height <= 800px) {
  .welcome-icon {
    font-size: 10rem !important
  }
}

@media (width < 1000px), (height <= 640px) {
  .welcome-icon {
    display: none !important;
  }
}

@media (width < 1000px) {
  .welcome-panel {
    overflow: visible;
    min-height: fit-content;
    padding: 1rem;
  }
  .welcome-subtitle {
    font-size: 1rem;
  }
  .welcome-title {
    font-size: 1rem;
    margin-bottom: 0;
  }
  .welcome-text {
    display: none;
  }
}

@media (height <= 640px) {
  .welcome-panel {
    display: none;
  }
}
</style>
