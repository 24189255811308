import { useIndexStore } from '@src/store'
import { fakeUser, authClientId, authTenantId } from '@src/config'
import { UserAgentApplication } from 'msal'

export const msalConfig = {
  auth: {
    clientId: authClientId,
    authority: `https://login.microsoftonline.com/${authTenantId}`,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
    forceRefresh: false,
  },
}
export const loginRequest = {
  scopes: [
    'openid',
    'profile',
    'user.read',
  ],
}

export async function configureMsal () {
  const msalClient = new UserAgentApplication(msalConfig)
  useIndexStore().changeMsal({
    client: msalClient,
    account: localStorage.getItem('account') || '',
    status: '',
  })
  msalClient.handleRedirectCallback(authCallback)
}

export async function authCallback (error, response) {
  const store = useIndexStore()
  const msal = store.msal
  return new Promise((resolve, reject) => {
    try {
      if (error) {
        throw error
      }
      if (!response) {
        throw 'Error: no response'
      }
      const account = msal.client.getAccount()
      if (!account) {
        throw 'Error: no account available for login'
      }
      localStorage.setItem('account', account)
      msal.status = 'success'
      msal.account = account
      store.changeMsal(msal)
      resolve(account)
    } catch (e) {
      msal.status = 'error'
      store.changeMsal(msal)
      reject(e)
    }
  })
}

export function getAccount () {
  const msal = useIndexStore().msal
  return msal.account || null
}

export function getUser () {
  if (fakeUser) {
    return {
      id: 42,
      username: 'mockuser',
      fullName: 'Mock user',
    }
  } else {
    const account = getAccount()
    return account ? {
      id: account.homeAccountId,
      username: account.username,
      fullName: account.name,
    } : null
  }
}

export function loginUser () {
  const store = useIndexStore()
  const msal = store.msal
  msal.status = 'loading'
  store.changeMsal(msal)
  msal.client.loginRedirect(loginRequest)
}

export function logoutUser (opLogout) {
  if (opLogout === undefined) {
    opLogout = false
  }
  const store = useIndexStore()
  const msal = store.msal
  const account = localStorage.getItem('account')
  if (account) {
    msal.status = ''
    msal.account = ''
    store.changeMsal(msal)
    localStorage.removeItem('account')
    if (opLogout) {
      msal.client.logout({account})
    }
  }
}
